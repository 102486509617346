import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "animate.css/animate.min.css";

const EventsPreview = () => {

  const [jsonContent, setJsonContent] = useState('');

  function loadJson(url) {
  var _json = url;
  axios.get(_json)
  .then(function (response) {

    //console.log(response);

    if(! response.data.data){
      //no event to list
      return;
    }

  var _jsonContent = response.data.data.map( (item, index) => {

    var date_init = new Date(item.date_init);

      var eventDateInit_0 = date_init.toLocaleDateString('en-US', {
        hour: 'numeric',
        minute: '2-digit',
      });

      var eventDateInit_1 = (eventDateInit_0).split(", ");
      var eventDateInit = eventDateInit_1.join("\n");

  if(item.isnext === 'next'){
    return (

      <div key={'d_' + item.id} className="ch-grid-event col-12 col-md-3">
      <ul className="ch-grid">
      <li>
<div className= {'ch-item item-' + item.isnext}>
<div className="ch-info">
<div className="ch-info-front ch-img-1" style={{ backgroundImage: `url(${item.image_thumb['url']})` }}>
<h3>{item.name}</h3>
</div>
<div className="ch-info-back">
  <h3><a href={"/events/" + item.slug}>{eventDateInit}</a></h3>
  <p><a href={"/events/" + item.slug}>View event</a></p>
</div>
</div>
</div>
</li>
</ul>
</div>

)} else{
  return '';
};

 });

 setJsonContent(_jsonContent);

  });
  }




  useEffect(() => {
    //console.log('fetch events preview')
    var source_url = 'https://discover.lilleymansion.com/api/v1/events-all';
    loadJson(source_url);
  },[]);


  return (
    <div className="row">
      {jsonContent}
    </div>
  );
};

export default EventsPreview;
