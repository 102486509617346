import React,{useEffect, useState} from 'react'
import { /*Link, Script, ScriptStrategy,*/ graphql } from "gatsby"

//import axios from 'axios';
import Layout from "./../../components/layout"
import ThemeContext from "./../../context/ThemeContext"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
//import useWindowSize from './../../hooks/useGatsbyWindowSize'
//import { useMediaQuery } from 'react-responsive'

import {gsap} from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin"
import { CustomEase } from "gsap/CustomEase"
import "animate.css/animate.min.css";
import EventsPreview from './../../components/EventsPreview'
//import ScrollAnimation from 'react-animate-on-scroll';
//import SvgEl from './../../components/SvgEl'
//import LOGO from './../../svg/logos'





gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(CustomEase);
gsap.config({autoKillThreshold: 1});


const EventsPage = ({ data }) => {

//console.log(data.photos.edges);


//  const [isLoaded, setIsLoaded] = useState(false);
//  const [isMobile, setIsMobile] = useState(false);


//  const mobile = useMediaQuery({ query: `(max-width: 767px)` });
//  const {width,height} = useWindowSize();
//    var device = '';










  useEffect(() => {
  //  setIsMobile(mobile);
  //  console.log('loaded')

  },[]);


  return (

    <ThemeContext.Consumer>
        {theme => (
    <div id="page">
    <div id="page-wrapper" className="mt-5">


    <div className="container pt-4 p-2 text-center">

    <h1 className="mt-5">EVENTS AT LILLEY MANSION</h1>

    </div>


    <div className="row">

    <div className="col-12 col-md-4">
    <a href="/events/private-parties">
    <div className="-image-container">
    <GatsbyImage
     className="image  isLoaded isVisible"
     fluid={data.photos.edges[0].node.childImageSharp.fluid}
      alt="Private Parties at Lilley Mansion"
     image={getImage(data.photos.edges[0].node)}
     />

    <h3 className="ttl-blog H4  text-center pt-3">PRIVATE PARTIES</h3>
    </div>
    </a>
    <p className="text-center"><small>CELEBRATING FRIENDS & FAMILY</small></p>
    </div>

    <div className="col-12 col-md-4">
    <a href="/events/weddings">
    <div className="-image-container">
    <GatsbyImage
     className="image  isLoaded isVisible"
     fluid={data.photos.edges[1].node.childImageSharp.fluid}
      alt="Weddings at Lilley Mansion"
     image={getImage(data.photos.edges[1].node)}
     />

    <h3 className="ttl-blog H4  text-center pt-3">WEDDINGS</h3>
    </div>
    </a>
    <p className="text-center"><small>YOUR SPECIAL DAY</small></p>
    </div>

    <div className="col-12 col-md-4">
    <a href="/events/events-at-lilley-mansion">
    <div className="-image-container">
    <GatsbyImage
     className="image  isLoaded isVisible"
     fluid={data.photos.edges[2].node.childImageSharp.fluid}
     alt="Events at Lilley Mansion"
     image={getImage(data.photos.edges[2].node)}
     />

    <h3 className="ttl-blog H4  text-center pt-3">HOUSE EVENTS</h3>
    </div>
    </a>
    <p className="text-center"><small>DISCOVER OUR EVENTS</small></p>
    </div>


</div>

<div className="container pt-4 p-2 text-center">
<EventsPreview/>

<hr/>
<p className="text-center p-2"> <a className="text-center p-2 font-lm" href="/events/events-archive/">View our past events archive</a></p>

</div>


    </div>
    </div>
  )}
      </ThemeContext.Consumer>

  )
}

EventsPage.Layout = Layout

export default EventsPage

export const Head = (props) => (
  <>
    <title>Events at lilley Mansion</title>
    <meta name="description" content="The Lilley Mansion is the elegant location for all of your special celebrations" />
    <meta property="og:url" content={'https://lilleymansion.com'+ props.location.pathname} />
    <meta property="og:type" content="article" />
    <meta property="og:title" content="The Lilley Mansion" />
    <meta property="og:description" content="The Lilley Mansion is the elegant location for all of your special celebrations" />
    <meta property="og:image" content="https://lilleymansion.com/og/lilley-mansion.jpg" />
    <link rel="preload" href="https://discover.lilleymansion.com/app/v1/page/13/private-parties" as="fetch"/>
    <link rel="preload" href="https://discover.lilleymansion.com/api/v1/culinary-events" as="fetch"/>
    <link rel="preload" href="https://discover.lilleymansion.com/app/v1/page/3/weddings" as="fetch"/>
  </>
)

export const query = graphql`
  {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }

    photos: allFile(
          sort: { fields: base, order: ASC }
          filter: {relativeDirectory: {eq: "events"}}
        ) {
          edges {
            node {
              id
              base
              childImageSharp {
                gatsbyImageData(
                  placeholder: DOMINANT_COLOR
                  height: 1200
                  quality: 80
                  formats: AUTO
                  transformOptions: {fit: COVER, cropFocus: ATTENTION}

                )
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
  }
`
